
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import { Field, Form } from "vee-validate";
import { object, string, number, array } from "yup";
import moment from "moment";
import phoneCodes from "@/core/data/phonecodes";
import VueHtml2pdf from "vue3-html2pdf";
import MemberFile from "./docs/MemberFile.vue";
import Nomination from "./docs/Nomination.vue";

import membersApi from "@/core/services/MembersApi";
import institutionApi from "@/core/services/InstitutionApi";
import duesApi from "@/core/services/DuesApi";
import promotionsApi from "@/core/services/PromotionsApi";
import geoApi from "@/core/services/GeoService";
import Places from "./crafted/widgets/Places.vue";
import axios from "axios";

export default defineComponent({
    name: "MemberDetails",
    components: {
        Form,
        Field,
        Places,
        VueHtml2pdf,
        MemberFile,
        Nomination,
    },
    data() {
        return {
            formKey: 0,
            placesKey: 0,
            youngSince: "",
            memberFileKey: 0,
            memberFileValues: [] as any,
            newAddress: false,
            editing: false,
            intoPromotions: false,
            intoDues: false,
            intoPro: false,
            testPersoRevue: true,
            removeInstitImage: false,
            testProRevue: true,
            targetBailliage: "",

            // Fake options
            titles: [] as any,
            sexes: [
                {
                    label: "Male",
                    value: "M",
                },
                {
                    label: "Female",
                    value: "F",
                },
            ],
            nationalities: [] as any,
            statuses: [] as any,
            grades: [] as any,
            filteredGrades: [] as any,
            functions: [] as any,
            filteredNatBailliages: [] as any,
            nBailliages: [] as any,
            pBailliages: [] as any,
            rBailliages: [] as any,
            spokenLanguages: [] as any,
            commanderLevelsOptions: [] as any,
            medalsOptions: [] as any,
            activities: [] as any,
            countries: [] as any,
            states: [] as any,
            parent1: {} as any,
            parent2: {} as any,
            cities: [] as any,
            types: [],
            stars: [
                {
                    label: "1",
                    value: "1",
                },
                {
                    label: "2",
                    value: "2",
                },
                {
                    label: "3",
                    value: "3",
                },
                {
                    label: "4",
                    value: "4",
                },
                {
                    label: "5",
                    value: "5",
                },
            ],
            food_styles: [] as any,
            credit_cards: [] as any,
            perimeters: ["CDR", "OMGD"],
            priceCategories: [],
            dueAmount: "",
            newDueAmount: "",
            currentPage: 1,
            actualFilter: "",
            actualCol: "",
            loading: true,

            // + general
            memberId: "",
            memberCode: "",
            memberTitle: {
                id: "",
                label: "",
            },
            memberCDRAdm: "",
            memberOMGDAdm: "",
            memberOMGDInduc: "",
            memberOMGDInducPlace: "",
            memberCompetitor: "",
            memberCompetitionYear: null,
            competitionAllowed: false,
            hasChildren: "",
            memberChildren: [] as any,
            childrenMemberChaine: "",
            remoteChild: "",
            memberFirstname: "" as any,
            memberLastname: "",
            memberSex: "",
            memberBirthday: "",
            memberLanguages: [] as any,
            memberSocial: "",
            memberNationality: {
                id: "",
                label: "",
            },
            memberMaritalStatus: {
                id: "",
                label: "",
            },
            memberSpouse: {
                id: "",
                label: "",
            },
            memberReference: "",
            memberGrade: {
                id: "",
                label: "",
            },
            memberFunction: {
                id: "",
                label: "",
            },
            memberOtherAssociations: "",
            memberNationalBailliage: {
                id: "",
                label: "",
                type: "",
                year: 0,
                is_active: false,
                parent: "",
            },
            memberProvincialBailliage: {
                id: "",
                label: "",
                type: "",
                year: 0,
                is_active: false,
                parent: "",
            },
            memberRegionalBailliage: {
                id: "",
                label: "",
                type: "",
                year: 0,
                is_active: false,
                parent: "",
            },
            memberSponsor1: {
                id: null as any,
                label: "",
            },
            memberSponsor2: {
                id: null as any,
                label: "",
            },
            memberIsOMGD: false,
            memberRadiationDate: "",
            nominationDate: "",
            inductionDate: "",
            omgdBailliage: "",
            omgdLocation: "",
            omgdInductionPlace: "",
            radiatedSince: "",
            memberIsPro: false,
            memberIsYoung: false,
            memberSpouseUpToDate: false,
            memberIsActive: true,
            memberIsYoungSommelier: false,
            memberIsYoungClerk: false,
            memberStatus: "",
            commanderLvls: [] as any,
            medals: [] as any,
            archiveReason: "",
            archiveReasons: [
                {
                    label: "Deceased",
                    value: "deceased",
                },
                {
                    label: "Terminated",
                    value: "terminated",
                },
            ],
            // - general

            currentMember: {},
            tempMember: {} as any,

            // + contact
            memberProfession: "",
            memberActivitySector: {
                id: "",
                label: "",
            },
            memberInstitution: {
                country: "",
                country_code: "",
                state: "",
                zip: "",
                street: "",
                city: "",
                additional_information: "",
            },
            countries_codes: [] as any,
            institutionPhone: {
                countryCode: 0,
                number: "",
            },
            institutionMobile: {
                countryCode: 0,
                number: "",
            },
            institutionEmail: "",
            memberAddress: {
                country: "",
                country_code: "",
                state: "",
                zip: "",
                street: "",
                city: "",
                additional_information: "",
            },
            memberPhone: {
                countryCode: 0,
                number: "",
            },
            memberMobile: {
                countryCode: 0,
                number: "",
            },
            memberEmail: "",
            // - contact

            // + professional institution
            institution: {
                id: null,
                name: "",
                pictureName: "",
                picture: "",
                type: {
                    id: "",
                    label: "",
                },
                speciality: "",
                foodStyles: [] as any,
                creditCards: [] as any,
                stars: 0,
                restaurant_nb: null,
                plaqueDisplayed: false,
                seats_nb: null,
                rooms_nb: null,
                benefits: null,
                website: "",
            },
            timestamp: Date.now(),
            institutionPicture: "" as any,
            institutionFoodStyles: [] as any,
            institutionCreditCards: [] as any,

            title: "" as any,
            foodStylesValue: "",
            creditCardsValue: "",
            institutionQuery: "",
            // - professional institution

            // + promotions
            promotions: [] as any,
            editingPromotion: false,
            newPromotion: {
                id: null as any,
                member: null as any,
                type: null as any,
                grade: null as any,
                price_category: null as any,
            } as any,
            promotionAmount: "",
            promotionTypes: [],
            promotionPriceCats: [],

            // - promotions

            // + dues
            selectedDues: [] as any,
            dues: [] as any,
            disableDueBtn: true,
            editingDue: false,
            duesTh: [
                {
                    label: "Type",
                    class: "min-w-70px w-25",
                    colName: "type",
                },
                {
                    label: "Amount",
                    class: "min-w-130px w-25",
                    colName: "amount",
                },
                {
                    label: "Relevant Year",
                    class: "min-w-130px w-25",
                    colName: "relevant_year",
                },
                {
                    label: "Date",
                    class: "min-w-120px",
                    colName: "date",
                },
            ],
            dueTypes: [],
            newDue: {
                id: null as any,
                member: null as any,
                relevant_year: null as any,
                perimeter: null as any,
                type: null as any,
                price_category: null as any,
                penalty: false,
            } as any,
            newLinkedDue: {
                id: null as any,
                member: null as any,
                relevant_year: null as any,
                perimeter: null as any,
                type: null as any,
                price_category: null as any,
                penalty: false,
            },
            totalPages: 0,
            // - dues

            remoteMembers: [] as any,
            remoteInstitutions: [] as any,
            locationInfo: {},
            addressIsLocked: true,
            proAddressIsLocked: true,
            checkAddress: false,
            phoneCodes: phoneCodes as any,
            childrenBailliagesLoaded: false,
            perm_pos: "",
            progressDialog: false,
            percentage: 0,
            nominationValues: [] as any,
            nominationKey: 0,
            reinstateAmount: "",
            reinstatePriceCategories: [] as any,
            reinstateTypes: [] as any,
            reinstatePerimeters: [] as any,
            reinstate: {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
            },
            renewal: {
                type: "",
                perimeter: "",
                price_category: "",
                relevant_year: "" as any,
                date: "",
                penalty: false,
            },
            disablePriceCategory: false,
            focusedMemberId: "",
            lockDueYear: false,
            lockNextDueYear: false,
            blockDue: false,
            loadExport: false,
            current_year: new Date().getFullYear(),
            disablePromotionType: false,
            disableDuePerimeter: false,
            disableDuePriceCategory: false,
            addingSecondDue: false,
            price_cat_options: [] as any,
            price_cat_options_next_step: [] as any,
            dueLabel: "",
            showDueNextStep: false,
            disableNextDueType: false,
            showRenewal: false,
            disableRenewalType: false,
            disableRenewalPerimeter: false,
            lockRenewalYear: false,
            disableRenewalPriceCategory: false,
            disableDueRelevantYear: false,
            renewalAmount: "",
            omgdModal: false,
            canAddDue: false,
            archiveNewMail: "",
        };
    },
    setup() {
        const resultsPerPage = 10;
        const route = useRoute();
        const router = useRouter();
        const addDueModalRef = ref<null | HTMLElement>(null) as any;
        const addPromotionModalRef = ref<null | HTMLElement>(null) as any;
        const archiveModalRef = ref<null | HTMLElement>(null) as any;
        const reinstateModalRef = ref<null | HTMLElement>(null) as any;
        const createTransferRef = ref<null | HTMLElement>(null) as any;
        const updateMailRef = ref<null | HTMLElement>(null) as any;

        const reinstateSchema = object().shape({
            renewalType: string().required().label("Type").nullable(),
            renewalPerimeter: string().required().label("Perimeter").nullable(),
            renewalPriceCategory: string()
                .required()
                .label("Price category")
                .nullable(),
            renewalYear: string().required().label("Renewal year").nullable(),
            renewalDate: string().required().label("Renewal date").nullable(),
        });

        const validationSchemaPro = object().shape({
            title: string().label("Title").nullable().required(),
            firstname: string().label("Firstname").nullable().required(),
            lastname: string().label("Lastname").nullable().required(),
            sex: string().label("Gender").nullable().required(),
            birthdate: string().label("Birthdate").nullable().required(),
            nationality: string().label("Nationality").nullable().required(),
            languages: array().label("Languages"),
            spouseMember: string().label("Spouse member").nullable(),
            otherAssociations: string().label("Other associations").nullable(),
            grade: string().label("Grade").nullable(),
            function: string().label("Function").nullable(),

            // Those 3 are defined dynamically later

            // nBailliage: string().label("National Bailliage").nullable(),
            // pBailliage: string().label("Provincial Bailliage").nullable(),
            // rBailliage: string().label("Regional Bailliage").nullable(),

            sponsor1: string().label("Sponsor 1").nullable(),
            sponsor2: string().label("Sponsor 2").nullable(),
            profession: string().label("Profession").nullable(),
            activitySector: string().label("Activity sector").nullable(),
            memberInstitutionName: string()
                .label("Institution name")
                .nullable(),
            memberInstitutionCountry: string()
                .label("Institution country")
                .nullable(),
            memberInstitutionState: string()
                .label("Institution state")
                .nullable(),
            memberInstitutionZip: string().label("Institution zip").nullable(),
            memberInstitutionStreet: string()
                .label("Institution street")
                .nullable(),
            memberInstitutionCity: string()
                .label("Institution city")
                .nullable(),
            institutionPhone: string().matches(
                /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                "Invalid pro phone number"
            ),
            memberInstitutionMobile: string()
                .label("Institution mobile")
                .nullable(),
            institutionEmail: string()
                .email()
                .label("Institution email")
                .nullable(),
            memberCountry: string().label("Member country").nullable(),
            memberState: string().label("Member state").nullable(),
            memberZip: string().label("Member zip").nullable(),
            memberStreet: string().label("Member street").nullable(),
            memberCity: string().label("Member city").nullable(),
            memberPhone: string()
                .matches(
                    /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                    "Invalid personal phone number"
                )
                .nullable(),
            memberMobile: string()
                .matches(
                    /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                    "Invalid personal phone number"
                )
                .nullable(),
            memberEmail: string().email().label("Member email").nullable(),
            memberCompetitionYear: string().nullable(),
            institutionType: string().label("Institution type").nullable(),
            institutionRestaurantNb: number()
                .typeError("Invalid restaurant number")
                .nullable(),
            institutionSeatsNb: number()
                .typeError("Invalid seats number")
                .nullable(),
            institutionRoomsNb: number()
                .typeError("Invalid rooms number")
                .nullable(),
            institutionBenefits: string()
                .label("Institution description")
                .required()
                .nullable(),
        });

        const validationSchema = object().shape({
            title: string().label("Title").nullable().required(),
            firstname: string().label("Firstname").nullable().required(),
            lastname: string().label("Lastname").nullable().required(),
            sex: string().label("Gender").nullable().required(),
            birthdate: string().label("Birthdate").nullable().required(),
            nationality: string().label("Nationality").nullable().required(),
            languages: array().label("Languages"),
            spouseMember: string().label("Spouse member").nullable(),
            otherAssociations: string().label("Other associations").nullable(),
            grade: string().label("Grade").nullable(),
            function: string().label("Function").nullable(),

            // Those 3 are defined dynamically later

            // nBailliage: string().label("National Bailliage").nullable(),
            // pBailliage: string().label("Provincial Bailliage").nullable(),
            // rBailliage: string().label("Regional Bailliage").nullable(),

            sponsor1: string().label("Sponsor 1").nullable(),
            sponsor2: string().label("Sponsor 2").nullable(),
            memberCompetitionYear: string().nullable(),

            profession: string().label("Profession").nullable(),
            activitySector: string().label("Activity sector").nullable(),
            memberInstitutionName: string()
                .label("Institution name")
                .nullable(),
            memberInstitutionCountry: string()
                .label("Institution country")
                .nullable(),
            memberInstitutionState: string()
                .label("Institution state")
                .nullable(),
            memberInstitutionZip: string().label("Institution zip").nullable(),
            memberInstitutionStreet: string()
                .label("Institution street")
                .nullable(),
            memberInstitutionCity: string()
                .label("Institution city")
                .nullable(),
            institutionPhone: string().matches(
                /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                "Invalid phone number"
            ),
            memberInstitutionMobile: string()
                .label("Institution mobile")
                .nullable(),
            institutionEmail: string()
                .email()
                .label("Institution email")
                .nullable(),
            memberCountry: string().label("Member country").nullable(),
            memberState: string().label("Member state").nullable(),
            memberZip: string().nullable().label("Member zip").nullable(),
            memberStreet: string().nullable().label("Member street").nullable(),
            memberCity: string().label("Member city").nullable(),
            memberPhone: string()
                .matches(
                    /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                    "Invalid phone number"
                )
                .nullable(),
            memberMobile: string()
                .matches(
                    /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                    "Invalid phone number"
                )
                .nullable(),
            memberEmail: string().email().label("Member email").nullable(),
        });

        const dueSchema = object().shape({
            relevantYear: string().required().label("Relevant year").nullable(),
            perimeter: string().required().label("Perimeter").nullable(),
            dueType: string().required().label("Due type").nullable(),
            priceCategory: string()
                .required()
                .label("Price category")
                .nullable(),
        });

        const nextDueSchema = object().shape({
            dueType: string().required().label("Due type").nullable(),
            perimeter: string().required().label("Perimeter").nullable(),
            priceCategory: string()
                .required()
                .label("Price category")
                .nullable(),
            relevantYear: string().required().label("Relevant year").nullable(),
            nextDueType: string().label("Due type").nullable(),
            nextPerimeter: string().label("Perimeter").nullable(),
            nextPriceCategory: string().label("Price category").nullable(),
            nextRelevantYear: string().label("Relevant year").nullable(),
        });

        const promotionSchema = object().shape({
            promotionType: string()
                .required()
                .label("Promotion type")
                .nullable(),
            promotionGrade: string().required().label("Grade").nullable(),
            promotionPriceCat: string()
                .required()
                .label("Price category")
                .nullable(),
        });

        const archiveSchema = object().shape({
            archiveReason: string().label("Archive reason").nullable(),
        });

        const updateMailSchema = object().shape({
            archiveNewMail: string()
                .email()
                .required()
                .label("Email")
                .nullable(),
        });

        const transferSchema = object().shape({
            targetBailliage: string()
                .required()
                .label("Target Bailliage")
                .nullable(),
        });

        const closePromotionModal = () => {
            hideModal(addPromotionModalRef.value);
        };

        const closeDueModal = () => {
            hideModal(addDueModalRef.value);
        };

        const closeArchiveModal = () => {
            hideModal(archiveModalRef.value);
        };

        const closeReinstateModal = () => {
            hideModal(reinstateModalRef.value);
        };

        const closeTransferModal = () => {
            hideModal(createTransferRef.value);
        };

        const closeUpdateMailModal = () => {
            hideModal(updateMailRef.value);
        };

        return {
            resultsPerPage,
            route,
            router,
            archiveSchema,
            validationSchema,
            validationSchemaPro,
            promotionSchema,
            dueSchema,
            nextDueSchema,
            archiveModalRef,
            closeTransferModal,
            createTransferRef,
            addDueModalRef,
            addPromotionModalRef,
            closePromotionModal,
            closeArchiveModal,
            closeReinstateModal,
            closeDueModal,
            reinstateModalRef,
            reinstateSchema,
            transferSchema,
            closeUpdateMailModal,
            updateMailSchema,
            updateMailRef,
        };
    },
    mounted() {
        this.perm_pos = localStorage.getItem("perm_pos") as any;
        this.title = document.querySelector(
            "#title-breadcrumbs h1"
        ) as HTMLElement;

        this.getFields();
        this.getMember();
        this.getPromotions();

        var phoneCodes = Object.values(this.phoneCodes).sort((a: any, b: any) =>
            parseInt(a) > parseInt(b) ? 1 : -1
        );
        const array = phoneCodes.map((c) => c);
        const filteredPhoneCodes = phoneCodes.filter(
            (index: any) => !array.includes(index + 1)
        );
        this.phoneCodes = filteredPhoneCodes;
    },
    beforeUnmount() {
        this.title.style.cssText = "display: flex !important;";
    },
    methods: {
        checkArchive(id: number) {
            membersApi
                .archiveMembers({ members: [id], status: "active" })
                .then((res: any) => {
                    if (res.success) {
                        this.fillReinstateForm(id);
                        document.getElementById("hidden-trigger")?.click();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                res.error && res.error.errorMessage
                                    ? res.error.errorMessage
                                    : "This member cannot be reinstated.",
                            duration: 7000,
                        });
                        document.getElementById("hidden-trigger-mail")?.click();
                    }
                });
        },
        updateArchiveMail() {
            membersApi
                .updateMember(this.memberId, {
                    contact: {
                        email: this.archiveNewMail,
                    },
                })
                .then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Email updated successfully",
                        });
                        this.archiveNewMail = "";
                        this.closeUpdateMailModal();
                        this.fillReinstateForm(this.memberId);
                        document.getElementById("hidden-trigger")?.click();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                res.error && res.error.errorMessage
                                    ? res.error.errorMessage
                                    : "An error occurred.",
                            duration: 5000,
                        });
                    }
                });
        },
        setActiveTab(tabIndex: number) {
            setTimeout(() => {
                var tabs = Array.from(
                    document.getElementsByClassName("member-nav-link")
                ) as any;
                var tabsContent = Array.from(
                    document.getElementsByClassName("member-tab-pane")
                ) as any;

                tabs.map((tab) => {
                    tab.classList.remove("active");
                });

                tabsContent.map((tab) => {
                    tab.classList.remove("active");
                    tab.classList.remove("show");
                });

                tabsContent[tabIndex].classList.add("show");
                tabsContent[tabIndex].classList.add("active");
                tabs[tabIndex].classList.add("active");
            }, 10);
        },
        sendMail() {
            membersApi.sendMail(this.memberId).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Mail sent successfully",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while sending the mail",
                    });
                }
            });
        },
        checkId(scope: string, e: any) {
            if (scope === "commander") {
                return this.commanderLvls.filter(
                    (item: any) => item.commander === e
                ).length
                    ? true
                    : false;
            } else if (scope === "medal") {
                return this.medals.filter((item: any) => item.medal === e)
                    .length
                    ? true
                    : false;
            }
        },
        checkSponsorValue(index: number) {
            this.remoteMembers = [];
            if (index === 1) {
                if (this.memberSponsor1.id === null) {
                    this.memberSponsor1.label = "";
                }
            } else if (index === 2) {
                if (this.memberSponsor2.id === null) {
                    this.memberSponsor2.label = "";
                }
            } else {
                if (this.memberSpouse.id === null) {
                    this.memberSpouse.label = "";
                }
            }
        },
        convertToUppercase(e) {
            setTimeout(() => {
                if (e === "lastname")
                    this.memberLastname = this.memberLastname.toUpperCase();
                else {
                    this.memberFirstname = this.memberFirstname
                        .toLowerCase()
                        .split(" ")
                        .map((part) => {
                            if (part.includes("-")) {
                                return part
                                    .split("-")
                                    .map(
                                        (subPart) =>
                                            subPart.charAt(0).toUpperCase() +
                                            subPart.slice(1)
                                    )
                                    .join("-");
                            } else {
                                return (
                                    part.charAt(0).toUpperCase() + part.slice(1)
                                );
                            }
                        })
                        .join(" ");
                }
            }, 50);
        },
        initDue() {
            this.dueLabel = "";
            // Si on me fait décommenter cette ligne je tue quelqu'un
            // this.disableDuePerimeter = true;
            this.editingDue = false;

            this.resetForm("Due");

            if (this.perm_pos !== "0") this.disableDueRelevantYear = true;

            membersApi
                .getSpecificDuesType({ member: this.memberId })
                .then((res: any) => {
                    this.dueTypes = res.data;
                });
        },
        formatCurrency(value: any) {
            return (
                value
                    .toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        currencyDisplay: "symbol",
                    })
                    .replace(/\u202F/g, ".") + " €"
            );
        },
        checkPromotionType() {
            if (this.perm_pos !== "0" && !this.editingPromotion) {
                this.promotionTypes = this.promotionTypes.filter(
                    (item: any) => item.label !== "Nomination"
                );
            }
        },
        transferMember() {
            membersApi
                .transferMember(this.memberId, {
                    national_bailliage: this.targetBailliage,
                })
                .then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message:
                                this.perm_pos === "0"
                                    ? "Member transferred successfully"
                                    : "Transfer request sent to HQ.",
                        });
                        this.closeTransferModal();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while transferring the member",
                        });
                    }
                });
        },
        fetchPriceCategories(scope: any) {
            var hasOMGD = false;

            this.disableDuePerimeter = false;

            // if (this.dueLabel === "Admission") {
            //     if (!hasOMGD && this.perm_pos !== "0") {
            //         this.newDue.perimeter = "OMGD";
            //         this.disableDuePerimeter = true;
            //     }
            // } else if (this.dueLabel === "Renewal") {
            //     if (!hasOMGD && this.perm_pos !== "0") {
            //         this.newDue.perimeter = "CDR";
            //         this.disableDuePerimeter = true;
            //     }
            // }

            const perimeters = this.dueTypes.filter(
                (item: any) => item.type.id === this.newDue.type
            ) as any;
            if (perimeters.length > 0)
                this.perimeters = perimeters[0].perimeter;

            var payload = {
                p: this.currentPage,
                presult: 100,
                member: this.memberId,
                current_selection: {
                    type:
                        scope === "due"
                            ? this.newDue.type
                            : scope === "reinstatement"
                            ? this.reinstate.type
                            : this.renewal.type,
                    perimeter:
                        scope === "due"
                            ? this.newDue.perimeter
                            : scope === "reinstatement"
                            ? this.reinstate.perimeter
                            : this.renewal.perimeter,
                },
            };
            if (typeof payload.current_selection.type === "string") {
                this.dueTypes.map((item: any) => {
                    if (item.label === payload.current_selection.type) {
                        payload.current_selection.type = item.id;
                    }
                });
            }

            if (
                !!payload.current_selection.type &&
                !!payload.current_selection.perimeter
            ) {
                membersApi.getPriceCategories(payload).then((response) => {
                    if (scope === "renewal") {
                        this.price_cat_options_next_step =
                            response.data.object_list;
                        this.price_cat_options_next_step.sort(
                            (a: any, b: any) => (a.amount > b.amount ? 1 : -1)
                        );
                    } else {
                        this.price_cat_options = response.data.object_list;
                        this.price_cat_options.sort((a: any, b: any) =>
                            a.amount > b.amount ? 1 : -1
                        );
                    }
                    if (this.price_cat_options.length === 1) {
                        if (scope === "due") {
                            this.newDue.price_category =
                                this.price_cat_options[0].id;
                            this.setDueAmount(
                                this.newDue.price_category,
                                false
                            );
                        } else if (scope === "reinstatement") {
                            this.reinstate.price_category =
                                this.price_cat_options[0].id;
                            this.setReinstateAmount(
                                this.reinstate.price_category,
                                false
                            );
                        } else if (scope === "renewal") {
                            this.renewal.price_category =
                                this.price_cat_options[0].id;
                            this.setReinstateAmount(
                                this.renewal.price_category,
                                true
                            );
                        }
                        if (this.perm_pos !== "0") {
                            this.disableDuePriceCategory =
                                this.disableDueRelevantYear = true;
                        } else {
                            this.disableDuePriceCategory =
                                this.disableDueRelevantYear = false;
                        }
                    } else {
                        this.disableDuePriceCategory = false;
                    }
                    const currentMonthNumber = new Date().getMonth() + 1;

                    if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                        this.newDue.relevant_year = new Date()
                            .getFullYear()
                            .toString();
                    } else {
                        this.newDue.relevant_year = (
                            new Date().getFullYear() + 1
                        ).toString();
                    }
                });
            }
        },
        handlePromotionFields(e: any) {
            // Grades
            membersApi
                .getGrades({
                    p: 1,
                    presult: 9999,
                    member: parseInt(this.route.params.member_id as string),
                })
                .then((res) => {
                    this.grades = res.data.object_list;

                    switch (e.code) {
                        case "ELEC":
                            this.grades = this.grades.filter(
                                (grade: any) =>
                                    grade.is_conseil === true &&
                                    grade.is_honoraire === false
                            );
                            break;
                        case "ELEC_H":
                            this.grades = this.grades.filter(
                                (grade: any) =>
                                    grade.is_conseil === true &&
                                    grade.is_honoraire === true
                            );
                            break;
                        case "PROM":
                            this.grades = this.grades.filter(
                                (grade: any) =>
                                    grade.is_conseil === false &&
                                    grade.is_honoraire === false
                            );
                            break;
                        case "PROM_H":
                            this.grades = this.grades.filter(
                                (grade: any) => grade.is_honoraire === true
                            );
                            break;
                        default:
                            break;
                    }
                });

            // Price cat
            if (e.code === "PROM_H" || e.code === "ELEC_H") {
                this.disablePromotionType = true;
                promotionsApi
                    .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                    .then((res) => {
                        this.promotionPriceCats = res.data.object_list;
                        this.promotionPriceCats.map((item: any) => {
                            if (item.amount === 0) {
                                this.newPromotion.price_category = item.id;
                                this.setPromotionAmount(item.id);
                            }
                        });
                        this.newPromotion.price_category = (
                            this.promotionPriceCats.filter(
                                (item: any) => item.code === "FREE"
                            )[0] as any
                        ).id;
                        this.setPromotionAmount(
                            this.newPromotion.price_category
                        );
                    });
            } else {
                this.disablePromotionType = false;
                this.newPromotion.price_category = "";
                if (this.perm_pos !== "0") {
                    this.promotionPriceCats = this.promotionPriceCats.filter(
                        (item: any) => item.amount !== 0
                    );
                }
            }
        },
        checkDueType(due: any) {
            if (
                (due.label === "Admission" || due.label === "Reinstatement") &&
                this.perm_pos !== "0"
            ) {
                this.lockDueYear = true;
                const currentMonthNumber = new Date().getMonth() + 1;
                if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                    this.newDue.relevant_year = new Date()
                        .getFullYear()
                        .toString();
                } else {
                    this.newDue.relevant_year = (
                        new Date().getFullYear() + 1
                    ).toString();
                }
            } else {
                this.lockDueYear = false;
            }
        },
        getReinstateFields() {
            membersApi
                .getDuesTypes({ p: 1, presult: 9999, reinstate: "" })
                .then((res) => {
                    this.reinstateTypes = res.data.object_list;
                    this.reinstateTypes.map((type: any) => {
                        if (type.label === "Reinstatement")
                            this.reinstate.type = type.id;
                    });
                });
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    if (this.perm_pos === "0") {
                        res.data.object_list.map((priceCat: any) => {
                            if (
                                priceCat.label === "Full" ||
                                priceCat.label === "Free"
                            )
                                this.reinstatePriceCategories.push(priceCat);
                        });
                    } else {
                        res.data.object_list.map((priceCat: any) => {
                            if (priceCat.label === "Full")
                                this.reinstatePriceCategories.push(priceCat);
                        });
                        this.reinstate.price_category =
                            this.reinstatePriceCategories[0].id;
                        this.setReinstateAmount(
                            this.reinstatePriceCategories[0].id,
                            false
                        );
                        this.disablePriceCategory = true;
                    }
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
        },
        fillReinstateForm(id: any) {
            this.focusedMemberId = id;
            this.reinstateTypes.map((item: any) => {
                if (item.code === "REIN") this.reinstate.type = item.id;
            });

            this.reinstatePerimeters = [{ id: 1, label: "CDR" }];
            this.reinstate.perimeter = "CDR";
            this.disableDuePerimeter = true;
            this.fetchPriceCategories("reinstatement");

            const currentMonthNumber = new Date().getMonth() + 1;

            if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                this.reinstate.relevant_year = new Date()
                    .getFullYear()
                    .toString();
            } else {
                this.reinstate.relevant_year = (
                    new Date().getFullYear() + 1
                ).toString();
            }
            this.setDate();
        },
        setDate() {
            // const result = new Date('2022', '2', '28').toLocaleDateString('en-GB');

            var date = new Date().getDate() as any;
            var month = (new Date().getMonth() + 1) as any;
            var year = new Date().getFullYear();

            if (date < 10) date = "0" + date;
            if (month < 10) month = "0" + month;

            setTimeout(() => {
                this.reinstate.date =
                    this.renewal.date = `${date}/${month}/${year}`;
            }, 200);
        },
        setDueAmount(e: any, linkedDue: boolean) {
            if (linkedDue === true) {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.newDueAmount = item.amount;
                    }
                });
            } else {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.dueAmount = item.amount;
                    }
                });
            }
        },
        setReinstateAmount(e: any, linkedDue: boolean) {
            if (linkedDue === true) {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.renewalAmount = item.amount;
                    }
                });
            } else {
                this.priceCategories.map((item: any) => {
                    if (item.id === e) {
                        this.reinstateAmount = item.amount;
                    }
                });
            }
        },
        initRenewal() {
            ElMessage({
                message: "You should now add a Renewal due.",
                type: "info",
            });
            this.showRenewal = true;
            this.dueLabel = "Renewal";
            // this.resetForm("Reinstate");

            this.reinstateTypes.map((item: any) => {
                if (item.code === "RENEW") this.renewal.type = item.id;
            });

            membersApi.getSpecificMember(this.memberId).then((res: any) => {
                if (res.data.is_omgd) {
                    this.reinstatePerimeters.push({ id: 2, label: "OMGD" });
                } else {
                    this.renewal.perimeter = "CDR";
                    this.disableRenewalPerimeter = true;
                }
                this.fetchPriceCategories("renewal");

                const currentMonthNumber = new Date().getMonth() + 1;

                if (currentMonthNumber >= 1 && currentMonthNumber <= 8) {
                    this.renewal.relevant_year = new Date()
                        .getFullYear()
                        .toString();
                } else {
                    this.renewal.relevant_year = (
                        new Date().getFullYear() + 1
                    ).toString();
                }
            });
        },
        unarchiveMember() {
            var isEmpty = false;

            for (var key in this.reinstate) {
                if (
                    this.reinstate[key] === "" ||
                    this.reinstate[key] === null
                ) {
                    isEmpty = true;
                }
            }

            if (!isEmpty) this.omgdModal = true;
            else
                ElMessage({
                    message: "Please fill all the reinstate fields",
                    type: "error",
                });
        },
        addReinstate(addOmgd: boolean) {
            var payload = {
                reinstatement: { member: this.memberId, ...this.reinstate },
                renewal: { member: this.memberId, ...this.renewal },
                omgd: addOmgd,
            };

            membersApi.addReinstatement(payload).then((res) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Reinstatement added!",
                    });
                    this.omgdModal = false;
                    this.closeReinstateModal();
                    this.getMember();
                } else {
                    ElMessage({
                        type: "error",
                        message: "Something went wrong!",
                    });
                }
            });
        },
        handleSizeChange() {
            this.getDues();
        },
        downloadPDF(pdf: string) {
            this.progressDialog = true;

            const preparePromotion = new Promise((resolve) => {
                if (pdf === "promotion") {
                    this.nominationValues = [];
                    membersApi
                        .getSpecificMember(this.route.params.member_id)
                        .then((res: any) => {
                            this.nominationValues.push({
                                type: "Promotion",
                                title: res.data.title
                                    ? res.data.title.label
                                    : "",
                                firstname: res.data.userprofile.firstname,
                                lastname: res.data.userprofile.lastname,
                                desc_before_grade:
                                    "Nous avons l’honneur de vous faire savoir qu’après examen de votre dossier, nous avons décidé de vous promouvoir au grade de",
                                desc_after_grade:
                                    "Suivant notre règlement, vous recevrez officiellement, lors d’un prochain Chapitre de votre choix, les insignes de votre nouveau grade.",
                                politeness:
                                    "Nous sommes heureux de vous adresser nos vives félicitations et nos salutations les plus confraternelles.",
                                grade: res.data.grade ? res.data.grade : "-",
                                bailliage: {
                                    national: res.data.userprofile
                                        .national_bailliage
                                        ? res.data.userprofile
                                              .national_bailliage.label
                                        : "",
                                    regional: res.data.userprofile
                                        .regional_bailliage
                                        ? res.data.userprofile
                                              .regional_bailliage.label
                                        : "",
                                },
                                city: "Paris",
                                date: moment()
                                    .locale("fr")
                                    .format("D MMMM YYYY"),
                                omgd: false,
                            });
                            setTimeout(() => {
                                resolve(this.nominationValues);
                            }, 3000);
                        });
                }
            });

            const prepareNomination = new Promise((resolve) => {
                if (pdf === "nomination") {
                    this.nominationValues = [];
                    membersApi
                        .getSpecificMember(this.route.params.member_id)
                        .then((res: any) => {
                            this.nominationValues.push({
                                type: "Nomination",
                                title: res.data.title
                                    ? res.data.title.label
                                    : "",
                                firstname: res.data.userprofile.firstname,
                                lastname: res.data.userprofile.lastname,
                                desc_before_grade:
                                    "Nous avons l’honneur de vous faire savoir qu’après examen de votre dossier, nous avons décidé de votre admission au sein de la Chaîne des Rôtisseurs au grade de",
                                desc_after_grade:
                                    "Suivant notre règlement, vous recevrez officiellement, lors d’un prochain Chapitre de votre choix, les insignes de votre grade. Le diplôme vous sera remis lors de votre intronisation.",
                                politeness:
                                    "Très honorés de vous compter parmi nous, nous vous prions de bien vouloir agréer l’expression de nos sentiments dévoués.",
                                grade: res.data.grade ? res.data.grade : "-",
                                bailliage: {
                                    national: res.data.userprofile
                                        .national_bailliage
                                        ? res.data.userprofile
                                              .national_bailliage.label
                                        : "",
                                    regional: res.data.userprofile
                                        .regional_bailliage
                                        ? res.data.userprofile
                                              .regional_bailliage.label
                                        : "",
                                },
                                city: "Paris",
                                date: moment()
                                    .locale("fr")
                                    .format("D MMMM YYYY"),
                                omgd: res.data.is_omgd ? true : false,
                            });
                            setTimeout(() => {
                                resolve(this.nominationValues);
                            }, 3000);
                        });
                }
            });

            switch (pdf) {
                case "member_file":
                    var memberDues = [] as any;
                    var memberPromotions = [] as any;

                    this.dues.map((due: any) => {
                        memberDues.push({
                            id: due.id,
                            type: due.type.label,
                            year: due.relevant_year,
                            date: due.date,
                        });
                    });

                    this.promotions.map((promotion: any) => {
                        memberPromotions.push({
                            id: promotion.id,
                            type: promotion.type.label,
                            year: promotion.relevant_year,
                            date: promotion.date,
                            grade: promotion.grade.label
                                ? promotion.grade.label
                                : "",
                            intronisation: promotion.intronisation_date
                                ? promotion.intronisation_date
                                : "",
                            country: promotion.intronisation_location
                                ? promotion.intronisation_location
                                : "",
                            city: "",
                        });
                    });

                    this.memberFileValues.push({
                        is_pro: this.memberIsPro,
                        title: this.memberTitle.label
                            ? this.memberTitle.label
                            : "",
                        firstname: this.memberFirstname,
                        lastname: this.memberLastname,
                        birthdate: this.memberBirthday,
                        nationality: this.memberNationality.label
                            ? this.memberNationality.label
                            : "",
                        code: this.memberCode,
                        profession: this.memberProfession,
                        activity_sector: this.memberActivitySector.label
                            ? this.memberActivitySector.label
                            : "",
                        function: this.memberFunction.label
                            ? this.memberFunction.label
                            : "",
                        grade: this.memberGrade.label,
                        national_bailliage: this.memberNationalBailliage.label,
                        regional_bailliage: this.memberRegionalBailliage.label,
                        provincial_bailliage:
                            this.memberProvincialBailliage.label,
                        sponsors: [
                            this.memberSponsor1
                                ? this.memberSponsor1.label
                                : "",
                            this.memberSponsor2
                                ? this.memberSponsor2.label
                                : "",
                        ],
                        dues: memberDues,
                        promotions: memberPromotions,
                        contact: {
                            address: this.memberAddress.street,
                            city: this.memberAddress.city,
                            zip: this.memberAddress.zip,
                            country: this.memberAddress.country,
                            phone: this.memberPhone.number,
                            fax: "?",
                            mobile: this.memberMobile.number,
                            email: this.memberEmail,
                        },
                        contact_pro: {
                            address: this.memberIsPro
                                ? this.memberInstitution.street
                                : "",
                            city: this.memberIsPro
                                ? this.memberInstitution.city
                                : "",
                            zip: this.memberIsPro
                                ? this.memberInstitution.zip
                                : "",
                            country: this.memberIsPro
                                ? this.memberInstitution.country
                                : "",
                            phone: this.memberIsPro
                                ? this.institutionPhone.number
                                : "",
                            fax: "?",
                            mobile: this.memberIsPro
                                ? this.institutionMobile.number
                                : "",
                            email: this.memberIsPro
                                ? this.institutionEmail
                                : "",
                            website: "?",
                        },
                    });

                    this.memberFileKey++;
                    (this.$refs.member_file as any).generatePdf();

                    break;
                case "promotion":
                    preparePromotion.then(() => {
                        this.nominationKey++;
                        (this.$refs.promotion as any).generatePdf();
                    });
                    break;
                case "nomination":
                    prepareNomination.then(() => {
                        this.nominationKey++;
                        (this.$refs.promotion as any).generatePdf();
                    });
                    break;
            }
        },
        setProgress(e: any) {
            this.percentage = e;

            if (this.percentage === 100) {
                setTimeout(() => {
                    this.progressDialog = false;
                    this.percentage = 0;
                }, 1000);
            }
        },
        resetForm(name: string) {
            switch (name) {
                case "Due":
                    this.dueTypes = this.dueTypes.filter(
                        (item: any) => item.code !== "REIN"
                    );
                    this.newDue = {
                        id: null as any,
                        member: null as any,
                        relevant_year: null as any,
                        perimeter: null as any,
                        type: null as any,
                        price_category: null as any,
                        penalty: false,
                    };
                    break;
                case "Promotion":
                    this.newPromotion = {
                        id: null as any,
                        member: null as any,
                        type: null as any,
                        grade: null as any,
                        price_category: null as any,
                    };
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                (document.querySelector("#resetForm" + name) as any).click();
            }, 150);
        },
        handleClear(scope: string) {
            switch (scope) {
                case "perso":
                    this.memberAddress = {
                        country: "",
                        country_code: "",
                        state: "",
                        city: "",
                        zip: "",
                        street: "",
                        additional_information: "",
                    };
                    break;
                case "pro":
                    this.memberInstitution = {
                        country: "",
                        country_code: "",
                        state: "",
                        city: "",
                        zip: "",
                        street: "",
                        additional_information: "",
                    };
                    break;
                case "newAddress":
                    if (this.newAddress) {
                        this.memberInstitution = {
                            country: "",
                            country_code: "",
                            state: "",
                            city: "",
                            zip: "",
                            street: "",
                            additional_information: "",
                        };
                        this.institution.name = "";
                        var institStreet = document.getElementById(
                            "autocomplete_memberInstitutionStreet"
                        ) as any;
                        institStreet.value = "";
                    }
                    break;
            }
        },
        setPlaceInfo(scope: string, value: any) {
            switch (scope) {
                case "pro":
                    this.memberInstitution = {
                        country: value.country,
                        country_code: value.iso2,
                        state: value.state,
                        zip: value.postalCode,
                        street: value.streetNumber + " " + value.street,
                        city: value.city,
                        additional_information:
                            this.memberInstitution.additional_information,
                    };
                    this.reloadLocations("pro", false);
                    break;
                case "perso":
                    console.log("value", value);

                    this.memberAddress = {
                        country: value.country,
                        country_code: value.iso2,
                        state: value.state,
                        zip: value.postalCode,
                        street: value.streetNumber + " " + value.street,
                        city: value.city,
                        additional_information:
                            this.memberAddress.additional_information,
                    };
                    // this.reloadLocations("member", false);
                    break;
                default:
                    break;
            }
        },
        redirectTo(destination: any) {
            var tabs = document.getElementsByClassName(
                "member-nav-link"
            ) as any;
            var addPromotionBtn = document.querySelector(
                "#addPromotion"
            ) as any;
            var addDueBtn = document.querySelector("#addDue") as any;

            if (destination === "promotions") {
                this.setActiveTab(tabs.length - 2);
                this.intoPromotions = true;
                this.intoDues = false;

                if (addPromotionBtn !== null) {
                    addPromotionBtn.click();
                }
            } else if (destination === "dues") {
                this.setActiveTab(tabs.length - 1);
                this.intoPromotions = false;
                this.intoDues = true;
                if (addDueBtn !== null) {
                    addDueBtn.click();
                }
            }
        },
        testSchema(values: any) {
            if (!this.memberIsPro) {
                this.validationSchema.validate(values).catch((res: any) => {
                    ElMessage({
                        type: "error",
                        message: res,
                    });
                    return 0;
                });
            } else if (this.memberIsPro) {
                this.validationSchemaPro.validate(values).catch((res: any) => {
                    ElMessage({
                        type: "error",
                        message: res,
                    });
                    return 0;
                });
            }
        },
        backToList() {
            // var currentPage;

            // if (this.route.params.page) currentPage = this.route.params.page;

            // if (this.memberIsActive) {
            this.router.push({
                name: "members-list",
                params: { page: 1 },
            });
            // } else {
            //     this.router.push({
            //         name: "members-archive",
            //         params: { page: currentPage },
            //     });
            // }
        },
        getCountryStates(countryCode: string) {
            if (countryCode !== "") {
                geoApi.getStatesByCountry(countryCode).then((response) => {
                    this.states = [];
                    response.map((item) => {
                        this.states.push({
                            id: item.id,
                            label: item.name,
                            iso2: item.iso2,
                        });
                    });
                });
            }
        },
        getCountryCities(countryCode: string) {
            if (countryCode !== "") {
                geoApi.getCitiesByCountry(countryCode).then((response) => {
                    this.cities = [];
                    response.map((item) => {
                        this.cities.push({
                            id: item.id,
                            label: item.name,
                        });
                    });
                });
            }
        },
        archiveMember(id: any, reason: string) {
            var payload = {
                members: [id],
                status: reason,
            } as any;

            if (reason === "active") {
                payload = {
                    ...payload,
                    due: this.reinstate,
                };
            }

            membersApi.archiveMembers(payload).then(() => {
                if (reason !== "active") {
                    this.closeArchiveModal();
                    ElMessage({
                        type: "info",
                        message: "Member archived.",
                    });
                    setTimeout(() => {
                        this.backToList();
                    }, 500);
                } else {
                    this.getDues();
                    this.closeReinstateModal();
                    if (this.perm_pos === "0") {
                        ElMessage({
                            type: "success",
                            message: "Member unarchived!",
                        });
                    } else {
                        ElMessage({
                            type: "info",
                            message: "Unarchive request sent to HQ.",
                        });
                    }
                }
                this.getMember();
            });
        },
        loadRemoteMembers(query, scope?: string) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    var payload = { query: query } as any;
                    if (scope && scope !== "") payload.type = scope;
                    membersApi.listRemote(payload).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    userprofile: {
                                        firstname:
                                            remoteMember.userprofile.firstname,
                                        lastname:
                                            remoteMember.userprofile.lastname,

                                        national_bailliage: {
                                            label: remoteMember.userprofile
                                                .national_bailliage
                                                ? remoteMember.userprofile
                                                      .national_bailliage.label
                                                : null,
                                        },
                                        provincial_bailliage: {
                                            label: remoteMember.userprofile
                                                .provincial_bailliage
                                                ? remoteMember.userprofile
                                                      .provincial_bailliage
                                                      .label
                                                : null,
                                        },
                                        regional_bailliage: {
                                            label: remoteMember.userprofile
                                                .regional_bailliage
                                                ? remoteMember.userprofile
                                                      .regional_bailliage.label
                                                : null,
                                        },
                                    },
                                    code: remoteMember.code,
                                    grade: remoteMember.grade
                                        ? remoteMember.grade
                                        : "-",
                                    is_pro: remoteMember.is_pro,
                                    is_young_sommelier:
                                        remoteMember.is_young_sommelier,
                                    is_cdr_uptodate:
                                        remoteMember.is_cdr_uptodate,
                                    is_active: remoteMember.is_active,
                                    due_date: remoteMember.due_date,
                                    due_type: remoteMember.due_type,
                                });
                            });

                            if (this.memberSponsor1.id !== null)
                                this.remoteMembers = this.remoteMembers.filter(
                                    (item: any) =>
                                        item.id !== this.memberSponsor1.id
                                );

                            if (this.memberSponsor2.id !== null)
                                this.remoteMembers = this.remoteMembers.filter(
                                    (item: any) =>
                                        item.id !== this.memberSponsor2.id
                                );

                            resolve("");
                        } else {
                            reject("");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        loadRemoteInstitutions(query) {
            return new Promise((resolve, reject) => {
                if (query) {
                    if (query !== "" && query.length >= 2) {
                        this.remoteInstitutions = [];
                        institutionApi
                            .listRemote({ query: query })
                            .then((response) => {
                                if (response.success) {
                                    response.data.map((remoteInstitution) => {
                                        this.remoteInstitutions.push({
                                            ...remoteInstitution,
                                            name: remoteInstitution.name,
                                            address: {
                                                country:
                                                    remoteInstitution.address
                                                        .country,
                                                state: remoteInstitution.address
                                                    .state,
                                                city: remoteInstitution.address
                                                    .city,
                                                street: remoteInstitution
                                                    .address.address,
                                                zip: remoteInstitution.address
                                                    .postal_code,
                                            },
                                            id: remoteInstitution.id,
                                        });
                                    });
                                    resolve("");
                                } else {
                                    reject("");
                                }
                            });
                    } else {
                        this.remoteMembers = [];
                        resolve("");
                    }
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncSpouse(queryString, cb) {
            this.loadRemoteMembers(queryString, "spouse").then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncSponsor(queryString, cb) {
            this.loadRemoteMembers(queryString, "sponsor").then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncInstitution(queryString, cb) {
            this.loadRemoteInstitutions(queryString).then(() => {
                cb(this.remoteInstitutions);
            });
        },
        handleSelect(item: any, input: any) {
            if (input === "spouse_member") {
                this.memberSpouse.label =
                    item.userprofile.firstname +
                    " " +
                    item.userprofile.lastname;
                this.memberSpouse.id = item.id;
            } else if (input === "sponsor1") {
                this.memberSponsor1.label =
                    item.userprofile.firstname +
                    " " +
                    item.userprofile.lastname;
                this.memberSponsor1.id = item.id;
            } else if (input === "sponsor2") {
                this.memberSponsor2.label =
                    item.userprofile.firstname +
                    " " +
                    item.userprofile.lastname;
                this.memberSponsor2.id = item.id;
            } else if (input === "institution") {
                console.log("instiiiiit", item);
                // var institStreet = document.getElementById("autocomplete_memberInstitutionStreet") as any;
                // institStreet.value = item.address.street ? item.address.street : "";

                this.institution.name = this.institutionQuery = item.name;
                this.institution.id = item.id;
                this.memberInstitution.state = item.address.state
                    ? item.address.state
                    : "";
                this.memberInstitution.country = item.address.country
                    ? item.address.country
                    : "";
                this.memberInstitution.city = item.address.city
                    ? item.address.city
                    : "";
                this.memberInstitution.zip = item.address.zip
                    ? item.address.zip
                    : "";
                this.institution.type = item.institution_type
                    ? item.institution_type
                    : "";
                this.institution.speciality = item.speciality
                    ? item.speciality
                    : "";
                this.institution.stars = item.stars ? item.stars : 0;
                this.institution.restaurant_nb = item.restaurant_amount
                    ? item.restaurant_amount
                    : 0;
                this.institution.plaqueDisplayed = item.chaine_plaque_displayed
                    ? true
                    : false;
                this.institution.seats_nb = item.seats_amount
                    ? item.seats_amount
                    : 0;
                this.institution.benefits = item.benefits ? item.benefits : "";
                this.institution.picture = item.picture ? item.picture : "";
                this.institution.foodStyles = item.food_styles
                    ? item.food_styles
                    : "";
                this.institution.creditCards = item.credit_cards
                    ? item.credit_cards
                    : "";
                this.institution.foodStyles = item.food_styles.map(
                    (item: any) => {
                        return item.id;
                    }
                );
                this.institution.creditCards = item.credit_cards.map(
                    (item: any) => {
                        return item.id;
                    }
                );
            } else if (input === "child") {
                this.memberChildren.push(item);
            }
            this.remoteMembers = [];
            this.remoteInstitutions = [];
        },
        addPromotion(editing: boolean) {
            this.newPromotion.member = parseInt(
                this.route.params.member_id as string
            );

            if (editing) {
                promotionsApi
                    .editPromotion(this.newPromotion.id, this.newPromotion)
                    .then(() => {
                        this.closePromotionModal();
                        ElMessage({
                            type: "success",
                            message: "Successfully edited promotion.",
                        });
                        this.getPromotions();
                    });
            } else {
                const payload = {
                    member: this.newPromotion.member,
                    grade: this.newPromotion.grade,
                    price_category: this.newPromotion.price_category,
                    type: this.newPromotion.type,
                };
                promotionsApi.addPromotion(payload).then(() => {
                    this.closePromotionModal();
                    ElMessage({
                        type: "success",
                        message: "Successfully added promotion.",
                    });
                    this.getPromotions();
                });
            }
        },
        deletePromotion(id: any) {
            ElMessageBox.confirm(
                "You have requested the deletion of this promotion.<br>Would you like to confirm this choice?",
                "Confirmation",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    promotionsApi.deletePromotions(id).then(() => {
                        this.getPromotions();
                        ElMessage({
                            type: "success",
                            message: "Successfully deleted promotion.",
                        });
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        cancelPromotion(id: any, status: any) {
            ElMessageBox.confirm(
                "You have requested the cancellation of this promotion.<br>Would you like to confirm this choice?",
                "Confirmation",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    promotionsApi.cancelPromotion(id).then(() => {
                        this.getPromotions();
                        ElMessage({
                            type: "success",
                            message:
                                "Promotion successfully " +
                                (status === 0 ? "cancelled." : "validated."),
                        });
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        getPromotions() {
            this.loading = true;
            promotionsApi
                .getPromotions({
                    p: 1,
                    presult: 100,
                    member: this.route.params.member_id,
                })
                .then((response) => {
                    this.promotions = response.data.promotion_list;
                    this.loading = false;
                    this.promotions.map((promotion: any) => {
                        if (promotion.status === "current") {
                            this.memberGrade.id = promotion.grade
                                ? promotion.grade.id
                                : null;
                            this.memberGrade.label = promotion.grade
                                ? promotion.grade.label
                                : null;
                        }
                    });
                });
        },
        editPromotion(promotion: any) {
            if (promotion.dashboard_status === 0 && !promotion.is_editable) {
                this.editingPromotion = true;
            } else if (promotion.is_editable) {
                this.editingPromotion = false;
            }
            this.newPromotion = {
                id: promotion.id,
                member: this.route.params.member_id,
                date: promotion.date,
                type: promotion.type.id,
                grade: promotion.grade.id,
                price_category: promotion.price_category.id,
            };
            this.promotionAmount = promotion.price_category.amount;
        },
        setPromotionAmount(e: any) {
            this.promotionPriceCats.map((item: any) => {
                if (item.id === e) {
                    this.promotionAmount = item.amount;
                }
            });
        },
        deleteDues(dueId: number) {
            ElMessageBox.confirm(
                "You have requested the deletion of this due.<br>Would you like to confirm this choice?",
                "Confirmation",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    duesApi.deleteDues([dueId]).then(() => {
                        ElMessage({
                            type: "success",
                            message: "Successfully deleted due.",
                        });
                        this.getDues();
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        cancelDue(dueId: number, status: any) {
            ElMessageBox.confirm(
                "You have requested the cancellation of this due.<br>Would you like to confirm this choice?",
                "Confirmation",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    duesApi.cancelDue([dueId]).then(() => {
                        ElMessage({
                            type: "success",
                            message:
                                "Due successfully " +
                                (status === 0 ? "cancelled." : "validated."),
                        });
                        this.getDues();
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        showDueModal() {
            var addDueBtn = document.querySelector("#addDue") as any;
            if (addDueBtn !== null) {
                addDueBtn.click();
            }
        },
        showPromotionModal() {
            var addPromotionBtn = document.querySelector(
                "#addPromotion"
            ) as any;
            if (addPromotionBtn !== null) {
                addPromotionBtn.click();
            }
        },
        addDue(editing: boolean) {
            this.newDue.member = this.route.params.member_id;
            this.blockDue = true;

            if (editing) {
                var payload = {
                    id: this.newDue.id,
                    member: this.route.params.member_id,
                    relevant_year: this.newDue.relevant_year,
                    perimeter: this.newDue.perimeter,
                    price_category: this.newDue.price_category,
                    penalty: this.newDue.penalty,
                };
                membersApi.editDue(this.newDue.id, payload).then((res) => {
                    this.closeDueModal();
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Successfully edited due!",
                        });
                        this.getDues();
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred.",
                        });
                    }
                    this.blockDue = false;
                });
                this.editingDue = false;
            } else {
                membersApi.addDues(this.newDue).then((res) => {
                    this.closeDueModal();
                    if (res.success) {
                        ElMessage({
                            type: "info",
                            message: "Request to add a due sent to the HQ.",
                        });
                        this.getDues();
                    } else if (res.status === 409) {
                        ElMessage({
                            type: "error",
                            message:
                                "This member has already a due at this year.",
                            duration: 3000,
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred.",
                        });
                    }
                    this.blockDue = false;
                });
            }
        },
        getSpecificDue(id: any) {
            var due: any;
            this.editingDue = true;
            duesApi.getSpecificDue(id).then((response) => {
                due = response.data;
                this.editDue(due);
                setTimeout(() => {
                    this.showDueModal();
                }, 250);
            });
        },
        editDue(due: any) {
            console.log(due);
            this.dueLabel = due.type.label;
            if (due.type.code === "REIN") this.disableDuePerimeter = true;
            else this.disableDuePerimeter = false;

            var checkPC = false;
            console.log(this.price_cat_options);

            this.price_cat_options.map((item: any) => {
                if (item.id === due.price_category.id) {
                    checkPC = true;
                    this.newDue.price_category = item.id;
                }
            });
            if (!checkPC) {
                this.newDue.price_category = null;
            }

            membersApi
                .getSpecificDuesType({ member: this.memberId })
                .then((res) => {
                    this.dueTypes = res.data.object_list;
                    this.newDue = {
                        id: due.id,
                        member: this.route.params.member_id,
                        relevant_year: due.relevant_year,
                        perimeter:
                            due.type.code === "REIN" ? "CDR" : due.perimeter,
                        type: due.type.label,
                        price_category: checkPC ? due.price_category.id : null,
                        date: due.date,
                    };
                    this.fetchPriceCategories("due");

                    this.dueAmount = due.price_category.amount;
                    this.disableDueBtn = false;
                });
        },
        getDues() {
            this.dues = [];
            this.selectedDues = [];
            var payload;
            this.loading = true;

            // On check s'il y a des filtres
            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    member: this.route.params.member_id,
                    p: 1,
                    presult: 9999,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    member: this.route.params.member_id,
                    p: 1,
                    presult: 9999,
                };
            }

            membersApi.getMemberDues(payload).then((res: any) => {
                this.dues = res.data.cotisation_list;
                this.totalPages = res.data.page_count;

                this.loading = false;
            });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox-dues") as any
            );

            this.selectedDues = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.dues.map((due: any) => {
                    this.selectedDues.push(due.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedDues = [];
            }
        },
        handleActionSelect(selectedDues: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + selectedDues.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedDues.push(selectedDues.id);
            } else {
                this.selectedDues.map((dueID: any) => {
                    if (dueID === selectedDues.id) {
                        this.selectedDues.splice(
                            this.selectedDues.indexOf(dueID),
                            1
                        );
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedDues = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            if (topCheckbox) topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getDues();
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container-bis") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getDues();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("ri-arrow-up-s-line") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName(
                        "ri-arrow-down-s-line"
                    ) as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handleImage(e) {
            this.institution.picture = e.target.files[0] as any;
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.institution.picture = ev.target.result as any;
                }
            };
            if (this.institution.picture)
                reader.readAsDataURL(this.institution.picture as any);
        },
        removeImage() {
            const input = document.getElementById("institPic") as any;
            if (input) input.value = "";

            this.institution.picture = "";
            this.institution.pictureName = "";
            this.removeInstitImage = true;
        },
        formatTags(e, tagifyInput) {
            var localFoodTags = [] as any;
            var localCreditTags = [] as any;
            if (e.target.value[0] === "[") {
                var parsedJson = JSON.parse(e.target.value);
                if (tagifyInput === "food_styles") {
                    this.foodStylesValue = "";
                    parsedJson.map((item: any) => {
                        localFoodTags.push(item.id);
                        if (this.foodStylesValue === "")
                            this.foodStylesValue = item.value;
                        else
                            this.foodStylesValue =
                                this.foodStylesValue + ", " + item.value;
                    });
                    this.institutionFoodStyles = [];
                    this.institutionFoodStyles = localFoodTags;
                } else if (tagifyInput === "credit_cards") {
                    this.creditCardsValue = "";
                    parsedJson.map((item: any) => {
                        localCreditTags.push(item.id);
                        if (this.creditCardsValue === "")
                            this.creditCardsValue = item.value;
                        else
                            this.creditCardsValue =
                                this.creditCardsValue + ", " + item.value;
                    });
                    this.institutionCreditCards = [];
                    this.institutionCreditCards = localCreditTags;
                }
            }
        },
        getCommanders() {
            // BD
            if (this.perm_pos === "2") {
                membersApi.getEligibleCommanders(this.memberId).then((res) => {
                    this.commanderLevelsOptions = res.data.commandeurs;
                });
            } else {
                membersApi
                    .getCommanders({
                        p: 1,
                        presult: 999,
                        arrival_date: this.memberCDRAdm,
                    })
                    .then((res) => {
                        this.commanderLevelsOptions = res.data.object_list;
                    });
            }
        },
        getBailliages() {
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National")
                        this.nBailliages.push(bailliage);
                });

                let bailliageSchema;

                if (this.rBailliages.length > 0) {
                    bailliageSchema = object().shape({
                        nBailliage: string()
                            .label("National Bailliage")
                            .nullable()
                            .required(),
                        pBailliage: string()
                            .label("Provincial Bailliage")
                            .nullable(),
                        rBailliage: string()
                            .label("Regional Bailliage")
                            .nullable(),

                        // 643e aller-retour client...
                        // .required(),
                    });
                } else {
                    bailliageSchema = object().shape({
                        nBailliage: string()
                            .label("National Bailliage")
                            .nullable(),
                        pBailliage: string()
                            .label("Provincial Bailliage")
                            .nullable(),
                        rBailliage: string()
                            .label("Regional Bailliage")
                            .nullable(),
                    });
                }

                if (this.memberIsPro) {
                    this.validationSchemaPro = this.validationSchemaPro.concat(
                        bailliageSchema
                    ) as any;
                } else {
                    this.validationSchema = this.validationSchema.concat(
                        bailliageSchema
                    ) as any;
                }

                this.formKey++;

                var tabs = document.getElementsByClassName(
                    "member-nav-link"
                ) as any;
                // Set the right active tab on page load
                // If we are editing a due
                if (this.route.params.editDueId && this.memberIsActive) {
                    this.intoDues = true;
                    this.intoPromotions = false;
                    this.setActiveTab(tabs.length - 2);
                    if (this.route.params.editDueId !== "edit") {
                        this.getSpecificDue(this.route.params.editDueId);
                    } else {
                        setTimeout(() => {
                            this.showDueModal();
                        }, 250);
                    }
                    // Else if we are editing a promotion
                } else if (
                    this.route.params.editPromotionId &&
                    this.memberIsActive
                ) {
                    this.intoPromotions = true;
                    this.intoDues = false;
                    this.setActiveTab(tabs.length - 3);
                    if (this.route.params.editPromotionId === "edit") {
                        setTimeout(() => {
                            this.showPromotionModal();
                        }, 250);
                    }
                    // Otherwise we set the first tab as active
                } else {
                    this.setActiveTab(0);
                }
            });
        },
        getFields() {
            membersApi.getSpokenLanguages().then((res: any) => {
                this.spokenLanguages = res.data;
            });
            membersApi.getNationalities({ p: 1, presult: 9999 }).then((res) => {
                this.nationalities = res.data.object_list;
            });
            membersApi.getFunctions({ p: 1, presult: 9999 }).then((res) => {
                this.functions = res.data.object_list;
            });
            membersApi
                .getMaritalStatuses({ p: 1, presult: 9999 })
                .then((res) => {
                    this.statuses = res.data.object_list;
                });
            membersApi
                .getGrades({
                    p: 1,
                    presult: 9999,
                    member: parseInt(this.route.params.member_id as string),
                })
                .then((res) => {
                    this.filteredGrades = res.data.object_list;
                });
            membersApi.getGrades({ p: 1, presult: 9999 }).then((res) => {
                this.grades = res.data.object_list;
            });
            membersApi
                .getActivitySectors({ p: 1, presult: 9999 })
                .then((res) => {
                    this.activities = res.data.object_list;
                });
            membersApi.getTitles({ p: 1, presult: 9999 }).then((res) => {
                this.titles = res.data.object_list;
            });
            membersApi
                .getInstitutionTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.types = res.data.object_list;
                });
            institutionApi
                .getFoodStyles({ p: 1, presult: 9999 })
                .then((res) => {
                    this.food_styles = res.data.object_list;
                });
            institutionApi
                .getCreditCards({ p: 1, presult: 9999 })
                .then((res) => {
                    this.credit_cards = res.data.object_list;
                });
            membersApi.getDuesTypes({ p: 1, presult: 9999 }).then((res) => {
                // this.dueTypes = this.reinstateTypes = res.data.object_list;
                this.reinstateTypes = res.data.object_list;
            });
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.priceCategories = this.price_cat_options =
                        res.data.object_list;
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
            promotionsApi
                .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.promotionPriceCats = res.data.object_list;
                    if (this.perm_pos !== "0") {
                        this.promotionPriceCats =
                            this.promotionPriceCats.filter(
                                (item: any) => parseInt(item.amount) !== 0
                            );
                    }
                });
            promotionsApi
                .getPromotionsTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.promotionTypes = res.data.object_list;

                    if (this.perm_pos !== "0") {
                        this.promotionTypes = this.promotionTypes.filter(
                            (item: any) =>
                                item.code !== "ELEC" && item.code !== "ELEC_H"
                        );
                    }
                });
        },
        getChildrenBailliages(national_bailliage_id: number) {
            if (national_bailliage_id) {
                membersApi
                    .getChildrenBailliages(national_bailliage_id)
                    .then((res) => {
                        var bailliages = res.data;
                        let pBailliages = [] as any;
                        let rBailliages = [] as any;
                        bailliages.map((bailliage: any) => {
                            if (bailliage.type === "Provincial")
                                pBailliages.push(bailliage);
                            if (bailliage.type === "Regional")
                                rBailliages.push(bailliage);
                        });

                        let bailliageSchema;

                        if (rBailliages.length > 0) {
                            bailliageSchema = object().shape({
                                nBailliage: string()
                                    .label("National Bailliage")
                                    .nullable()
                                    .required(),
                                pBailliage: string()
                                    .label("Provincial Bailliage")
                                    .nullable(),
                                rBailliage: string()
                                    .label("Regional Bailliage")
                                    .nullable(),

                                // 643e aller-retour client...
                                // .required(),
                            });
                        } else {
                            bailliageSchema = object().shape({
                                nBailliage: string()
                                    .label("National Bailliage")
                                    .nullable(),
                                pBailliage: string()
                                    .label("Provincial Bailliage")
                                    .nullable(),
                                rBailliage: string()
                                    .label("Regional Bailliage")
                                    .nullable(),
                            });
                        }

                        if (this.memberIsPro) {
                            this.validationSchemaPro.concat(bailliageSchema);
                        } else {
                            this.validationSchema.concat(bailliageSchema);
                        }

                        this.childrenBailliagesLoaded = true;
                        this.formKey++;
                        this.pBailliages = pBailliages;
                        this.rBailliages = rBailliages;
                    });
            }
        },
        getMember() {
            this.loading = true;
            membersApi
                .getSpecificMember(this.route.params.member_id)
                .then((member: any) => {
                    this.currentMember = member.data;
                    console.log("Fetched member :", this.currentMember);

                    this.memberId = member.data.id;

                    membersApi
                        .getMedals({ p: 1, presult: 9999 })
                        .then((res) => {
                            this.medalsOptions = res.data.object_list;
                        });

                    this.memberCode = member.data.code;
                    this.hasChildren = member.data.children_over_18
                        ? "Yes"
                        : "No";
                    this.childrenMemberChaine = member.data.children_in_chaine
                        ? "Yes"
                        : "No";
                    this.memberChildren = member.data.childrens.length
                        ? member.data.childrens
                        : [];

                    this.memberCompetitor = member.data.is_competitor
                        ? "Yes"
                        : "No";
                    this.competitionAllowed = member.data.is_competition_allowed
                        ? true
                        : false;
                    this.memberCompetitionYear = member.data.young_date
                        ? member.data.young_date
                        : null;
                    this.memberTitle.id = member.data.title
                        ? member.data.title.id
                        : null;
                    this.memberTitle.label = member.data.title
                        ? member.data.title.label
                        : null;
                    this.memberLanguages = member.data.spoken_languages
                        ? member.data.spoken_languages.map((lang: any) => {
                              return lang.id;
                          })
                        : [];
                    this.memberCDRAdm = member.data.admission_date_cdr
                        ? member.data.admission_date_cdr
                        : null;
                    this.memberOMGDAdm = member.data.omgd_nomination_date
                        ? member.data.omgd_nomination_date
                        : null;
                    this.memberOMGDInduc = member.data.omgd_intronisation_date
                        ? member.data.omgd_intronisation_date
                        : null;
                    this.memberOMGDInducPlace = member.data
                        .omgd_intronisation_chapitre_location
                        ? member.data.omgd_intronisation_chapitre_location
                        : null;

                    this.memberFirstname = member.data.userprofile.firstname
                        ? member.data.userprofile.firstname
                        : null;

                    this.memberLastname = member.data.userprofile.lastname
                        ? member.data.userprofile.lastname
                        : null;

                    this.memberSex = member.data.sex ? member.data.sex : null;
                    this.parent1 = member.data.parent1
                        ? member.data.parent1
                        : null;
                    this.parent2 = member.data.parent2
                        ? member.data.parent2
                        : null;

                    this.memberBirthday = member.data.birthdate
                        ? member.data.birthdate
                        : null;

                    this.memberNationality.id = member.data.nationality
                        ? member.data.nationality.id
                        : null;
                    this.memberNationality.label = member.data.nationality
                        ? member.data.nationality.label
                        : null;

                    this.memberFunction.id = member.data.function
                        ? member.data.function.id
                        : null;
                    this.memberFunction.label = member.data.function
                        ? member.data.function.label
                        : null;

                    this.memberMaritalStatus.id = member.data.marital_status
                        ? member.data.marital_status.id
                        : null;
                    this.memberMaritalStatus.label = member.data.marital_status
                        ? member.data.marital_status.label
                        : null;

                    this.memberGrade.label = member.data.grade;

                    this.memberIsOMGD = member.data.is_omgd;
                    this.memberRadiationDate = member.data.omgd_radiation_date;

                    this.getDues();

                    this.nominationDate = member.data.omgd_nomination_date
                        ? member.data.omgd_nomination_date
                        : null;
                    this.inductionDate = member.data.omgd_intronisation_date
                        ? member.data.omgd_intronisation_date
                        : null;
                    this.radiatedSince = member.data.omgd_radiation_date
                        ? member.data.omgd_radiation_date
                        : null;
                    this.omgdBailliage = member.data
                        .omgd_intronisation_bailliage
                        ? member.data.omgd_intronisation_bailliage.label
                        : "";
                    this.omgdLocation = member.data
                        .omgd_intronisation_chapitre_location
                        ? member.data.omgd_intronisation_chapitre_location
                        : "";

                    this.youngSince = member.data.young_date
                        ? member.data.young_date
                        : null;

                    this.omgdInductionPlace = this.omgdBailliage
                        ? this.omgdBailliage + ", " + this.omgdLocation
                        : this.omgdLocation;

                    if (
                        this.omgdBailliage === null &&
                        this.omgdLocation === null
                    )
                        this.omgdInductionPlace = "";

                    this.memberIsPro = member.data.is_pro;
                    this.memberIsYoung = member.data.is_young;
                    this.memberSpouseUpToDate = member.data.is_spouse;
                    this.memberStatus = member.data.status;
                    member.data.status === "active" ||
                    member.data.status === "Active"
                        ? (this.memberIsActive = true)
                        : (this.memberIsActive = false);

                    this.memberSpouse.id = member.data.spouse_member
                        ? member.data.spouse_member
                        : "";

                    if (this.memberSpouse.id !== "") {
                        membersApi
                            .getSpecificMember(this.memberSpouse.id)
                            .then((spouse: any) => {
                                this.memberSpouse.label =
                                    spouse.data.userprofile.firstname +
                                    " " +
                                    spouse.data.userprofile.lastname;
                            });
                    }
                    this.memberSponsor1.id = member.data.sponsor1
                        ? member.data.sponsor1
                        : null;

                    if (this.memberSponsor1.id !== null) {
                        membersApi
                            .getSpecificMember(this.memberSponsor1.id)
                            .then((sponsor: any) => {
                                this.memberSponsor1.label =
                                    sponsor.data.userprofile.firstname +
                                    " " +
                                    sponsor.data.userprofile.lastname;
                            });
                    }
                    this.memberSponsor2.id = member.data.sponsor2
                        ? member.data.sponsor2
                        : null;

                    if (this.memberSponsor2.id !== null) {
                        membersApi
                            .getSpecificMember(this.memberSponsor2.id)
                            .then((sponsor: any) => {
                                this.memberSponsor2.label =
                                    sponsor.data.userprofile.firstname +
                                    " " +
                                    sponsor.data.userprofile.lastname;
                            });
                    }

                    this.memberOtherAssociations = member.data
                        .other_associations
                        ? member.data.other_associations
                        : "";

                    if (member.data.userprofile.national_bailliage) {
                        this.memberNationalBailliage.id =
                            member.data.userprofile.national_bailliage.id;
                        this.memberNationalBailliage.label =
                            member.data.userprofile.national_bailliage.label;
                        this.memberNationalBailliage.is_active =
                            member.data.userprofile.national_bailliage.is_active;
                        this.memberNationalBailliage.type =
                            member.data.userprofile.national_bailliage.type;
                        this.memberNationalBailliage.year =
                            member.data.userprofile.national_bailliage.year;
                        this.memberNationalBailliage.parent =
                            member.data.userprofile.national_bailliage.parent;

                        this.getChildrenBailliages(
                            parseInt(this.memberNationalBailliage.id)
                        );
                        setTimeout(() => {
                            this.filteredNatBailliages =
                                this.nBailliages.filter(
                                    (bailliage: any) =>
                                        bailliage.id !==
                                        this.memberNationalBailliage.id
                                );
                        }, 2000);
                    }

                    if (member.data.userprofile.provincial_bailliage) {
                        this.memberProvincialBailliage.id =
                            member.data.userprofile.provincial_bailliage.id;
                        this.memberProvincialBailliage.label =
                            member.data.userprofile.provincial_bailliage.label;
                        this.memberProvincialBailliage.is_active =
                            member.data.userprofile.provincial_bailliage.is_active;
                        this.memberProvincialBailliage.type =
                            member.data.userprofile.provincial_bailliage.type;
                        this.memberProvincialBailliage.year =
                            member.data.userprofile.provincial_bailliage.year;
                        this.memberProvincialBailliage.parent =
                            member.data.userprofile.provincial_bailliage.parent;
                    }

                    if (member.data.userprofile.regional_bailliage) {
                        this.memberRegionalBailliage.id =
                            member.data.userprofile.regional_bailliage.id;
                        this.memberRegionalBailliage.label =
                            member.data.userprofile.regional_bailliage.label;
                        this.memberRegionalBailliage.is_active =
                            member.data.userprofile.regional_bailliage.is_active;
                        this.memberRegionalBailliage.type =
                            member.data.userprofile.regional_bailliage.type;
                        this.memberRegionalBailliage.year =
                            member.data.userprofile.regional_bailliage.year;
                        this.memberRegionalBailliage.parent =
                            member.data.userprofile.regional_bailliage.parent;
                    }

                    this.memberIsYoungSommelier = member.data.is_young_sommelier
                        ? true
                        : false;

                    this.memberIsYoungClerk = member.data.is_young_clerk
                        ? true
                        : false;

                    member.data.commanders.map((commander: any) => {
                        this.commanderLvls.push({
                            commander: commander.id,
                            date: commander.date,
                            is_editable: commander.is_editable,
                            location: commander.location
                                ? commander.location
                                : null,
                        });
                    });

                    this.getCommanders();
                    this.getBailliages();

                    member.data.medals.map((medal: any) => {
                        this.medals.push({
                            medal: medal.id,
                            date: medal.date,
                            is_editable: medal.is_editable,
                            location: medal.location ? medal.location : null,
                        });
                    });

                    this.memberProfession = member.data.profession
                        ? member.data.profession
                        : "";

                    this.memberActivitySector.id = member.data.activity_sector
                        ? member.data.activity_sector.id
                        : null;
                    this.memberActivitySector.label = member.data
                        .activity_sector
                        ? member.data.activity_sector.label
                        : null;

                    if (member.data.institution) {
                        this.institution.name = member.data.institution.name;
                        this.institutionQuery = member.data.institution.name;
                        if (member.data.institution.address) {
                            this.memberInstitution.country =
                                member.data.institution.address.country;
                            this.memberInstitution.city =
                                member.data.institution.address.city;
                            this.memberInstitution.street =
                                member.data.institution.address.address;
                            this.memberInstitution.zip =
                                member.data.institution.address.postal_code;
                            this.memberInstitution.state =
                                member.data.institution.address.state;
                            this.memberInstitution.additional_information =
                                member.data.institution.address.additional_information;
                        }
                    }

                    if (member.data.address) {
                        this.memberAddress.country =
                            member.data.address.country;
                        this.memberAddress.state = member.data.address.state;
                        this.memberAddress.zip =
                            member.data.address.postal_code;
                        this.memberAddress.street = member.data.address.address;
                        this.memberAddress.city = member.data.address.city;
                        this.memberAddress.additional_information =
                            member.data.address.additional_information;
                    }

                    geoApi.getCountries().then((res) => {
                        res.map((country: any) => {
                            this.countries.push({
                                id: country.id,
                                label: country.name,
                                iso2: country.iso2,
                            });

                            this.countries_codes.push({
                                id: country.id,
                                label: country.iso2,
                            });
                        });

                        setTimeout(() => {
                            if (this.memberAddress.country) {
                                this.countries.map((country: any) => {
                                    if (
                                        country.label ===
                                            this.memberAddress.country ||
                                        country.iso2 ===
                                            this.memberAddress.country
                                    ) {
                                        this.memberAddress.country_code =
                                            country.iso2;
                                        this.getCountryStates(
                                            this.memberAddress.country_code
                                        );
                                        this.getCountryCities(
                                            this.memberAddress.country_code
                                        );
                                    }
                                });
                            }
                        }, 2000);
                    });

                    this.checkAddress = true;

                    if (member.data.contact) {
                        this.memberPhone.number = member.data.contact.phone;
                        this.memberPhone.countryCode =
                            member.data.contact.phone_cc;
                        this.memberMobile.number = member.data.contact.mobile;
                        this.memberMobile.countryCode =
                            member.data.contact.mobile_cc;
                        this.memberEmail = member.data.contact.email;
                        this.memberSocial = member.data.contact.linkedin;
                    }

                    if (member.data.contact_pro) {
                        // this.institutionEmail = member.data.contact_pro.email;
                        this.institutionPhone.countryCode =
                            member.data.contact_pro.phone_cc;
                        // this.institutionPhone.number = member.data.contact_pro.phone;
                        this.institutionMobile.countryCode =
                            member.data.contact_pro.mobile_cc;
                        this.institutionMobile.number =
                            member.data.contact_pro.mobile;
                    }

                    this.institution.id = member.data.institution
                        ? member.data.institution.id
                        : null;
                    this.institution.name = member.data.institution
                        ? member.data.institution.name
                        : "";
                    if (member.data.institution) {
                        this.institution.picture = member.data.institution
                            .picture
                            ? member.data.institution.picture +
                              "?t=" +
                              Date.now()
                            : "";
                        // this.institution.b64Picture = "";

                        this.institutionEmail = member.data.institution.contact
                            ? member.data.institution.contact.email
                            : "";
                        this.institutionPhone.number = member.data.institution
                            .contact
                            ? member.data.institution.contact.phone
                            : "";
                        this.institution.stars = member.data.institution.stars;
                        this.institution.restaurant_nb =
                            member.data.institution.restaurant_amount;
                        this.institution.plaqueDisplayed =
                            member.data.institution.chaine_plaque_displayed;
                        this.institution.seats_nb =
                            member.data.institution.seats_amount;
                        this.institution.rooms_nb =
                            member.data.institution.rooms_amount;
                        if (member.data.institution.food_styles.length > 0) {
                            member.data.institution.food_styles.map(
                                (fs: any) => {
                                    this.institution.foodStyles.push(fs.id);
                                }
                            );
                        }
                        if (member.data.institution.credit_cards.length > 0) {
                            member.data.institution.credit_cards.map(
                                (credit_card: any) => {
                                    this.institution.creditCards.push(
                                        credit_card.id
                                    );
                                }
                            );
                        }
                        this.institution.benefits =
                            member.data.institution.benefits !== "" ||
                            member.data.institution.benefits !== null
                                ? member.data.institution.benefits
                                : "";

                        if (member.data.institution.speciality !== null)
                            this.institution.speciality =
                                member.data.institution.speciality;
                        else this.institution.speciality = "";
                        if (member.data.institution.institution_type) {
                            this.institution.type.id =
                                member.data.institution.institution_type.id;
                            this.institution.type.label =
                                member.data.institution.institution_type.label;
                        }

                        this.institution.website = member.data.institution
                            .website
                            ? member.data.institution.website
                            : "";
                    }

                    this.canAddDue = true;

                    if (this.memberIsActive) {
                        setCurrentPageBreadcrumbs(
                            this.memberLastname +
                                " " +
                                this.memberFirstname +
                                " - #" +
                                this.memberCode,
                            [
                                {
                                    link: "/members/list/",
                                    label: "Members list",
                                },
                            ]
                        );
                    } else {
                        setCurrentPageBreadcrumbs(
                            this.memberLastname +
                                " " +
                                this.memberFirstname +
                                " - #" +
                                this.memberCode,
                            [
                                {
                                    link: "/members/archives/",
                                    label: "Archives",
                                },
                            ]
                        );
                    }

                    if (this.title)
                        this.title.style.cssText = "display: none!important;";
                    this.loading = false;
                    this.placesKey++;
                })
                .catch((error: any) => {
                    const errorMsg = error.toString();
                    console.log("errorMsg", errorMsg);

                    if (errorMsg.includes("404")) {
                        this.router.push({ name: "404" });
                    }
                });
        },
        checkFormBeforeUpdate() {
            let medals = this.medals;
            let commanders = this.commanderLvls;

            medals.map((medal: any, i: number) => {
                if (medal.medal === "") medals.splice(i, 1);
                if (
                    medal.medal !== "" &&
                    (medal.date === "" || medal.date === null)
                )
                    medal.date = moment(new Date()).format("DD/MM/YYYY");
            });
            commanders.map((commanderLvl: any, j: number) => {
                if (commanderLvl.commander === "") commanders.splice(j, 1);
                if (
                    commanderLvl.commander !== "" &&
                    (commanderLvl.date === "" || commanderLvl.date === null)
                )
                    commanderLvl.date = moment(new Date()).format("DD/MM/YYYY");
            });

            this.tempMember = {
                id: parseInt(this.route.params.member_id as string),
                code: this.memberCode,
                title: this.memberTitle.id,
                spoken_languages: this.memberLanguages,
                userprofile: {
                    firstname: this.memberFirstname,
                    lastname: this.memberLastname,
                    national_bailliage: this.memberNationalBailliage.id,
                    provincial_bailliage: this.memberProvincialBailliage.id,
                    regional_bailliage: this.memberRegionalBailliage.id,
                },
                nationality: this.memberNationality.id,
                marital_status: this.memberMaritalStatus.id,
                //grade: this.memberGrade.id,
                function: this.memberFunction.id,
                commanders: commanders,
                medals: medals,
                activity_sector: this.memberActivitySector.id,
                is_competitor: this.memberCompetitor === "Yes" ? true : false,
                competition_year: this.memberCompetitionYear
                    ? parseInt(this.memberCompetitionYear as any)
                    : null,
                // contact_pro: {
                //     phone: this.institutionPhone.number,
                //     phone_cc: this.institutionPhone.countryCode,
                //     mobile: this.institutionMobile.number,
                //     mobile_cc: this.institutionMobile.countryCode,
                //     email: this.institutionEmail,
                // },
                institution: {
                    id: this.newAddress ? null : this.institution.id,
                    food_styles: this.institution.foodStyles,
                    credit_cards: this.institution.creditCards,
                    contact: {
                        phone: this.institutionPhone.number,
                        email: this.institutionEmail,
                    },
                    address: {
                        address: this.memberInstitution.street,
                        city: this.memberInstitution.city,
                        state: this.memberInstitution.state,
                        country: this.memberInstitution.country,
                        postal_code: this.memberInstitution.zip,
                        additional_information:
                            this.memberInstitution.additional_information,
                    },
                    speciality: this.institution.speciality
                        ? this.institution.speciality
                        : null,
                    stars: this.institution.stars,
                    // picture: this.institution.picture,
                    //picture: fileInstance,
                    restaurant_amount: this.institution.restaurant_nb,
                    seats_amount: this.institution.seats_nb,
                    rooms_amount: this.institution.rooms_nb,
                    chaine_plaque_displayed: this.institution.plaqueDisplayed,
                    name: this.institutionQuery
                        ? this.institutionQuery
                        : this.institution.name,
                    benefits: this.institution.benefits,
                    institution_type: this.institution.type.id,
                    website: this.institution.website,
                },
                address: {
                    address: this.memberAddress.street,
                    city: this.memberAddress.city,
                    state: this.memberAddress.state,
                    country: this.memberAddress.country,
                    postal_code: this.memberAddress.zip,
                    additional_information:
                        this.memberAddress.additional_information,
                },
                contact: {
                    phone: this.memberPhone.number,
                    phone_cc: this.memberPhone.countryCode,
                    mobile: this.memberMobile.number,
                    mobile_cc: this.memberMobile.countryCode,
                    email: this.memberEmail,
                    linkedin: this.memberSocial,
                },
                childrens: this.memberChildren.map((child) => {
                    return child.id;
                }),
                birthdate: this.memberBirthday,
                sex: this.memberSex,
                other_associations: this.memberOtherAssociations,
                profession: this.memberProfession,
                // is_young_sommelier: this.memberIsYoungSommelier,
                // is_young_clerk: this.memberIsYoungClerk,
                is_omgd: this.memberIsOMGD,
                is_pro: this.memberIsPro,
                sponsor1: this.memberSponsor1.id,
                sponsor2: this.memberSponsor2.id,
                spouse_member: this.memberSpouse.id,
                children_over_18: this.hasChildren === "Yes" ? true : false,
                children_in_chaine:
                    this.childrenMemberChaine === "Yes" ? true : false,
            };
            this.updateMember(this.tempMember);
        },
        updateMember(member: any) {
            membersApi.updateMember(member.id, member).then((response: any) => {
                if (response.status === 200) {
                    // this.commanderLvls = [];
                    // this.medals = [];
                    this.editing = false;
                    this.addressIsLocked = true;

                    const fileInput = document.getElementById(
                        "institPic"
                    ) as any;
                    let file = "" as any;
                    let fileInstance = "" as any;

                    // if (fileInstance)
                    file = fileInput?.files[0];

                    if (file) {
                        fileInstance = new File([file], file.name);

                        const formData = new FormData();
                        formData.append("file", fileInstance);
                        axios
                            .post(
                                "/api/v1/institution/" +
                                    member.institution.id +
                                    "/upload-image/",
                                formData,
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then((response: any) => {
                                this.getMember();
                                console.log(response);
                            })
                            .catch((error: any) => {
                                console.log(error);
                            });
                    }
                    if (this.removeInstitImage) {
                        this.tempMember.institution.picture = "";
                        membersApi
                            .updateMember(member.id, this.tempMember)
                            .then(() => {
                                this.getMember();
                            });
                    }

                    ElMessage({
                        type: "success",
                        message: "Member updated!",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message:
                            "Unable to update member. Please check all the fields across the tabs.",
                    });
                }
            });
        },
        setHeader(label: string) {
            switch (label) {
                case "infos":
                    this.intoPromotions = false;
                    this.intoDues = false;
                    this.intoPro = false;
                    break;
                case "pro":
                    this.intoPromotions = false;
                    this.intoDues = false;
                    this.intoPro = true;
                    break;
                case "promotions":
                    this.intoPromotions = true;
                    this.intoDues = false;
                    this.intoPro = false;
                    break;
                case "dues":
                    this.intoPromotions = false;
                    this.intoDues = true;
                    this.intoPro = false;
                    break;
            }
        },
        handleFileInstitution(res, file) {
            this.institution.picture = URL.createObjectURL(file);
        },
        beforeAvatarUpload(file) {
            //const isImg = file.type === "image/*";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isImg) {
            //     console.log("L'avatar doit être une image !");
            // }
            if (!isLt2M) {
                console.log("L'avatar ne peut pas excéder 2Mb !");
            }
            return isLt2M;
        },
        validateNumber(number: string, type: string, phone: string) {
            number = number.split(" ").join("");

            if (number[0] === "0") number = number.substr(1);
            if (type === "institution") {
                if (phone === "phone") {
                    this.institutionPhone.number = number;
                } else {
                    this.institutionMobile.number = number;
                }
            } else {
                if (phone === "phone") {
                    this.memberPhone.number = number;
                } else {
                    this.memberMobile.number = number;
                }
            }
        },
        handlePhoneSeparator(focus: string, index: number) {
            var e = Array.from(
                document.getElementsByClassName("phone-separator")
            ) as any;
            var cc = document.querySelector("#countrycode" + index) as any;
            var number = document.querySelector("#phonenumber" + index) as any;

            if (e && cc && number) {
                for (let i = 0; i < e.length; i++) {
                    if ((focus === "in" || focus === "change") && i === index)
                        e[i].style.backgroundColor = "#27347d";
                    else if (focus === "blur" && i === index)
                        e[i].style.backgroundColor = "#E4E6EF";
                    else if (
                        focus === "over" &&
                        i === index &&
                        cc !== document.activeElement &&
                        number !== document.activeElement
                    )
                        e[i].style.backgroundColor = "#c0c4cc";
                    else if (
                        cc !== document.activeElement &&
                        number !== document.activeElement
                    )
                        e[i].style.backgroundColor = "#E4E6EF";
                }
            }
        },
        reloadLocations(scope: string, unlock: boolean) {
            if (scope === "pro") {
                if (unlock === true)
                    this.proAddressIsLocked = !this.proAddressIsLocked;
                if (this.memberInstitution.country) {
                    this.getCountryStates(this.memberInstitution.country_code);
                    this.getCountryCities(this.memberInstitution.country_code);

                    if (this.memberInstitution.state) {
                        this.getCountryCities(
                            this.memberInstitution.country_code
                        );
                    }
                }
            } else if (scope === "member") {
                if (unlock === true)
                    this.addressIsLocked = !this.addressIsLocked;
                if (this.memberAddress.country) {
                    this.getCountryStates(this.memberAddress.country_code);
                    this.getCountryCities(this.memberAddress.country_code);

                    if (this.memberAddress.state) {
                        this.getCountryCities(this.memberAddress.country_code);
                    }
                }
            }
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "transfer":
                        return "Transfert";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Renewal":
                        return "Renewal";
                    case "adhesion":
                        return "Adhésion";
                    case "radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
    },
});
