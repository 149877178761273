import apiTool from "./index";

export default {
    getCountries() {
        return apiTool.geoGet("countries").then(response => {
            return response;
        });
    },
    getStatesByCountry(countryCode) {
        return apiTool.geoGet("countries/" + countryCode + "/states").then(response => {
            return response;
        });
    },
    getCitiesByCountry(countryCode) {
        return apiTool.geoGet("countries/" + countryCode  + "/cities").then(response => {
            return response;
        });
    }
};
