<template>
    <div>
        <Field :name="fieldName" v-slot="{ errorMessage }" v-model="location">
            <el-form-item :error="errorMessage">
                <el-input
                    v-on:keydown.enter.prevent
                    :disabled="disabled ? true : false"
                    placeholder="Start typing..."
                    class="custom-search"
                    :id="'autocomplete_' + fieldName"
                    v-model="location"
                    clearable
                    @input="handleInput"
                    @clear="handleClear"
                >
                </el-input>
            </el-form-item>
        </Field>
    </div>
</template>

<style lang="scss">
.pac-container::after {
    display: none;
}

.pac-container {
    padding: 1rem 0.5rem;
    font-family: "Proxima-nova", sans-serif;

    .pac-item-query {
        display: block;
        height: 20px;
    }

    .pac-item-query,
    .pac-matched {
        color: #5a6080;
    }

    span {
        color: #9497ab;
    }
}

.pac-icon {
    display: none;
}
</style>

<script>
import { defineComponent } from "vue";
import { Field } from "vee-validate";

export default defineComponent({
    name: "places",
    emits: ["getPlaceInfo", "getPlace", "input", "clear"],
    components: { Field },
    props: ["value", "disabled", "fieldName", "initialVal", "formatAddress"],
    data() {
        return {
            location: "",
            addressInfo: "",
            content: this.value,
            place: "",
            locationDetails: {},
            checkAddress: false,
        };
    },
    mounted() {
        // console.log("initialVal", this.$props);
        if (this.initialVal) {
            // console.log("alors bien ou bien?");
            this.location = this.initialVal;
        }
        var autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("autocomplete_" + this.fieldName));
        //console.log("autocomplete", autocomplete);
        window.google.maps.event.addListener(
            autocomplete,
            "place_changed",
            function () {
                this.checkAddress = false;
                var locationInfo = {
                    country: null,
                    iso2: null,
                    state: null,
                    city: null,
                    postalCode: null,
                    street: null,
                    streetNumber: null,
                    reset: function () {
                        this.country = null;
                        this.state = null;
                        this.city = null;
                        this.postalCode = null;
                        this.street = null;
                        this.streetNumber = null;
                    },
                };
                // Segment results into usable parts.
                var place = autocomplete.getPlace(),
                    address = place.address_components;

                // console.log("place", place);

                // Reset location object.
                locationInfo.reset();

                // Save the individual address components.
                for (var i = 0; i < address.length; i++) {
                    var component = address[i].types[0];
                    // console.log(address[i]);
                    switch (component) {
                        case "country":
                            locationInfo.country = address[i]["long_name"];
                            locationInfo.iso2 = address[i]["short_name"];
                            break;
                        case "administrative_area_level_1":
                            locationInfo.state = address[i]["long_name"];
                            break;
                        case "locality":
                            locationInfo.city = address[i]["long_name"];
                            break;
                        case "postal_code":
                            locationInfo.postalCode = address[i]["long_name"];
                            break;
                        case "route":
                            locationInfo.street = address[i]["long_name"];
                            break;
                        case "street_number":
                            locationInfo.streetNumber = address[i]["long_name"];
                            break;
                        default:
                            break;
                    }
                }
                if (this.formatAddress === true) {
                    if (this.inverseStreet(locationInfo.iso2)) {
                        this.location = locationInfo.street + " " + locationInfo.streetNumber;
                    } else if (locationInfo.streetNumber && locationInfo.street) {
                        this.location = locationInfo.streetNumber + " " + locationInfo.street;
                    } else if (locationInfo.streetNumber && !locationInfo.street) {
                        this.location = "";
                    } else if (!locationInfo.streetNumber && locationInfo.street) {
                        this.location = locationInfo.street;
                    } else {
                        this.location = locationInfo.city;
                    }
                } else {
                    this.locationDetails = {
                        iso2: locationInfo.iso2,
                        name: place.name,
                        address: locationInfo.streetNumber + " " + locationInfo.street,
                        country: locationInfo.country,
                        state: locationInfo.state,
                        city: locationInfo.city,
                        postalCode: locationInfo.postalCode,
                    };
                }
                this.addressInfo = locationInfo;
                this.sendInfo();
            }.bind(this)
        );
    },
    methods: {
        inverseStreet(iso2) {
            const countries = ["AT", "TR"];

            if (countries.includes(iso2)) {
                return true;
            }
        },
        sendInfo() {
            this.$emit("getPlaceInfo", this.addressInfo);
            this.$emit("getPlace", this.locationDetails);
            this.checkAddress = true;
        },
        handleInput() {
            this.$emit("input", this.location);
        },
        handleClear() {
            this.$emit("clear");
        },
    },
});
</script>
