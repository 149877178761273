import apiTool from "./index";
import ApiService from "./ApiService";

const LIST_REMOTE = "api/v1/institution/list-remote/";
const GET_FOOD_STYLES = "api/v1/food-style/list/";
const GET_CREDIT_CARDS = "api/v1/credit-card/list/";
const UPDATE_INSTITUTION = "api/v1/institution/<pk>/update/";

export default {
    listRemote(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_REMOTE, payload).then(response => {
            return response.data;
        });
    },
    getFoodStyles(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_FOOD_STYLES, payload).then(response => {
            return response.data;
        });
    },
    getCreditCards(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_CREDIT_CARDS, payload).then(response => {
            return response.data;
        });
    },
    updateInstitution(id, payload) {
        ApiService.setHeader();
        return ApiService.post(UPDATE_INSTITUTION.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    }
};